body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.flex-centered {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  msTransform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

label.scale-label {
  position: absolute;
  left: 8px;
  top: -4px;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  color: #808080;
}

.capitalise-first ::first-letter {
  text-transform: capitalize;
}
