.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

/*
 THESE TWO ARE TO FIX THE SIDEBAR PROBLEM ON DEV ENVIRONMENT
*/
.MuiDrawer-docked {
  z-index: 1; 
}

.RaLayout-content-4 {
  z-index: 2;
}

.App-link {
  color: #61dafb;
}

#wrap { width: 600px; height: 390px; padding: 0; overflow: hidden; }
#frame { width: 1070px; height: 520px; border: 1px solid rgb(110, 110, 110); }
#frame { zoom: 1; -moz-transform: scale(1.0); -moz-transform-origin: 0 0; }


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
